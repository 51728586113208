import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"listsPage"},[_c('Title',{attrs:{"title":"Showlists"}}),(_vm.singleListPage === undefined)?_c(VTextField,{staticClass:"listInput",attrs:{"append-icon":"mdi-plus","label":"Add List","type":"text","rules":_vm.listRules},on:{"click:append":_vm.addList,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addList.apply(null, arguments)}},model:{value:(_vm.addListInput),callback:function ($$v) {_vm.addListInput=$$v},expression:"addListInput"}}):_vm._e(),(_vm.showLists.length > 0)?_c('div',{staticClass:"expandButtons"},[(_vm.listPanels.length == 0)?_c(VBtn,{attrs:{"small":""},on:{"click":function($event){return _vm.expandAll()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-expand")]),_vm._v(" Expand all")],1):_c(VBtn,{attrs:{"small":""},on:{"click":function($event){return _vm.collapseAll()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-collapse")]),_vm._v(" Collapse all")],1)],1):_vm._e(),_c('br'),_c('div',{staticClass:"listsBox"},[_c(VExpansionPanels,{attrs:{"multiple":"","accordion":""},model:{value:(_vm.listPanels),callback:function ($$v) {_vm.listPanels=$$v},expression:"listPanels"}},_vm._l((_vm.showLists),function(list,i){return _c(VExpansionPanel,{key:`list` + i},[_c(VExpansionPanelHeader,[_c('div',{staticClass:"listHeader"},[_c('span',{staticClass:"listHeaderTitle"},[_c('router-link',{attrs:{"to":{
                  path: `/lists?list=${list.id}`,
                }}},[_vm._v(_vm._s(list.name))]),(list.ownerAccountId == _vm.GetAccountId())?_c(VBtn,{staticClass:"listHeaderBtn",attrs:{"icon":"","small":""},on:{"click":[function($event){return _vm.deleteList(list.id)},function($event){$event.stopPropagation();}]}},[_c(VIcon,[_vm._v("mdi-trash-can")])],1):_vm._e(),(
                  _vm.GetShowListEntries(list.id) !== undefined &&
                  _vm.GetShowListEntries(list.id).length > 0 &&
                  _vm.listPanels.length > 0 &&
                  _vm.listPanels.indexOf(i) !== -1
                )?_c('div',{staticClass:"expandProgressBox"},[(
                    _vm.progressPanels[i] === undefined ||
                    _vm.progressPanels[i].length == 0
                  )?_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":[function($event){return _vm.expandListProgress(i, list.id)},function($event){$event.stopPropagation();}]}},[_c(VIcon,[_vm._v("mdi-arrow-expand")])],1):_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":[function($event){return _vm.collapseListProgress(i)},function($event){$event.stopPropagation();}]}},[_c(VIcon,[_vm._v("mdi-arrow-collapse")])],1)],1):_vm._e()],1)])]),_c(VExpansionPanelContent,[_c(VExpansionPanels,{attrs:{"multiple":"","accordion":""},model:{value:(_vm.progressPanels[i]),callback:function ($$v) {_vm.$set(_vm.progressPanels, i, $$v)},expression:"progressPanels[i]"}},_vm._l((_vm.GetShowListEntries(list.id)),function(show,ei){return _c(VExpansionPanel,{key:`entry` + ei,staticClass:"showListEntryPanel"},[_c(VExpansionPanelHeader,{staticClass:"showListEntryPanelHeader"},[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"0"}},[_c(VListItem,{attrs:{"single-line":""}},[_c('img',{staticClass:"entryThumbnail",attrs:{"src":show.imageMedium}}),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-1 listEntryTitle"},[_c('router-link',{attrs:{"to":{
                            path: `/showpage?s=${show.tvMazeId}`,
                          }},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(show.name))]),(list.ownerAccountId == _vm.GetAccountId())?_c(VBtn,{staticClass:"listHeaderBtn",attrs:{"icon":"","small":""},on:{"click":[function($event){return _vm.deleteShowFromList(show.showId, list.id)},function($event){$event.stopPropagation();}]}},[_c(VIcon,[_vm._v(" mdi-trash-can")])],1):_vm._e()],1),_c(VListItemSubtitle,[_vm._v("Last Episode: "+_vm._s(show.lastEpisode ? `S${String( show.lastEpisode.seasonNumber ).padStart(2, "0")} E${String( show.lastEpisode.episodeNumber ).padStart(2, "0")} (${new Date( show.lastEpisode.airstamp ).toLocaleDateString("de-DE", _vm.timeOptions)})` : "No information.")+" · Next Episode: "+_vm._s(show.nextEpisode ? `S${String( show.nextEpisode.seasonNumber ).padStart(2, "0")} E${String( show.nextEpisode.episodeNumber ).padStart(2, "0")} (${new Date( show.nextEpisode.airstamp ).toLocaleDateString("de-DE", _vm.timeOptions)})` : " No information yet."))])],1)],1)],1)],1),_c(VExpansionPanelContent,[(!_vm.showListCollaborators)?_c('div',[_vm._v(" No collaborators added yet. ")]):_c('div',[_c(VDataTable,{attrs:{"headers":_vm.collaboratorHeaders,"items":show.progresses,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.collaboratorAccountId`,fn:function({ item }){return [_c('div',{staticClass:"collaboratorEntry"},[(
                            _vm.getCollaboratorAvatar(
                              item.collaboratorAccountId
                            ) == 'default.png'
                          )?_c('img',{staticClass:"profilePic",attrs:{"src":require("../assets/images/acc_circle.png")}}):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.apiUri +
                            '/avatars/' +
                            _vm.getCollaboratorAvatar(item.collaboratorAccountId)}}),_vm._v(" "+_vm._s(_vm.getCollaboratorName(item.collaboratorAccountId))+" ")])]}},{key:`item.lastSeenEpisode`,fn:function({ item }){return [_c('span',{on:{"click":function($event){return _vm.updateProgress(
                            item.collaboratorAccountId,
                            item.id,
                            show.seasons
                          )}}},[_vm._v(_vm._s(item.lastSeenEpisode == "-" ? "Not set." : item.lastSeenEpisode))])]}},{key:`item.status`,fn:function({ item }){return [_c(VChip,{staticClass:"ma-2",attrs:{"color":_vm.statusTagColors[item.status],"text-color":"white"},on:{"click":function($event){return _vm.updateStatus(item.collaboratorAccountId, item.id)}}},[_vm._v(_vm._s(_vm.statusTags[item.status]))])]}},{key:`item.delete`,fn:function({ item }){return [(
                          item.delete === undefined &&
                          list.ownerAccountId == _vm.GetAccountId() &&
                          item.collaboratorAccountId != _vm.GetAccountId()
                        )?_c(VBtn,{staticClass:"listHeaderBtn",attrs:{"icon":"","small":""},on:{"click":[function($event){return _vm.removeCollaborator(
                            list.id,
                            item.collaboratorAccountId
                          )},function($event){$event.stopPropagation();}]}},[_c(VIcon,[_vm._v(" mdi-trash-can")])],1):_vm._e()]}}],null,true)})],1)])],1)}),1),_c('br'),(list.ownerAccountId == _vm.GetAccountId())?_c(VFooter,[_c('div',{staticClass:"listEditBox"},[(_vm.GetNonCollaboratingFriends(list.id).length > 0)?_c('div',{staticClass:"addCollaboratorBox"},[_c('span',{staticClass:"collaboratorHead"},[_vm._v("Add collaborator:")]),_c(VSelect,{staticClass:"collaboratorSelector",attrs:{"items":_vm.GetNonCollaboratingFriends(list.id),"label":"Select friend to add."},model:{value:(_vm.selectedCollaboratorName[list.id]),callback:function ($$v) {_vm.$set(_vm.selectedCollaboratorName, list.id, $$v)},expression:"selectedCollaboratorName[list.id]"}}),_c(VBtn,{staticClass:"addBtn",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.addCollaborator(list.id)}}},[_c(VIcon,[_vm._v(" mdi-plus ")])],1)],1):_vm._e()])]):_vm._e()],1)],1)}),1)],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"#8ab83a","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('LastSeenSelector',{attrs:{"visible":_vm.isUpdatingProgress,"seasons":_vm.seasonsForLastSeen,"progressId":_vm.progressIdForLastSeen},on:{"stopUpdatingProgress":_vm.stopUpdatingProgress,"displaySnackbar":_vm.displaySnackbar}}),_c('StatusSelector',{attrs:{"visible":_vm.isUpdatingStatus,"progressId":_vm.progressIdForStatus},on:{"stopUpdatingStatus":_vm.stopUpdatingStatus}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }