import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"90%","scrollable":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Select last seen episode: ")]),_c(VCardText,{staticClass:"selectorBox"},[_c('div',{staticClass:"seasonsHeader"},[_vm._v("Season:")]),_c('div',{staticClass:"seasonsBox"},[_c(VTabs,{staticClass:"selectorSeasonTabs",attrs:{"vertical":""}},[_c('perfect-scrollbar',{attrs:{"options":_vm.scrollbarOptions}},_vm._l((_vm.seasons),function(season){return _c('div',{key:`s_` + season.number,staticClass:"seasonEntry",on:{"click":function($event){return _vm.selectSeason(season.id)}}},[_c(VTab,[_vm._v(_vm._s(season.name !== "" && !_vm.IsMobile() ? season.name + " (S" + season.number + ")" : "Season " + season.number))])],1)}),0)],1)],1),_c('div',{staticClass:"episodesHeader"},[_vm._v("Episode:")]),_c('div',{staticClass:"episodesBox"},[_c('perfect-scrollbar',{attrs:{"options":_vm.scrollbarOptions}},_vm._l((_vm.getEpisodes(_vm.selectedSeason)),function(episode){return _c('div',{key:`e_` + episode.id,staticClass:"episodeEntry",on:{"click":function($event){return _vm.selectEpisode(episode)}}},[_c(VBtn,[_vm._v(_vm._s(episode.number ? episode.number : "Special (" + episode.title + ")"))])],1)}),0)],1)]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.hideSelection(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }