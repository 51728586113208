
import { Component, Vue } from "vue-property-decorator";
import Header from "./components/header/header.vue";
import Sidebar from "./components/navbar/sidebar.vue";
import Bottombar from "./components/navbar/bottombar.vue";

@Component({
  components: {
    Header,
    Sidebar,
    Bottombar,
  },
})
export default class App extends Vue {
  screenWidth = 0;
  mounted() {
    this.screenWidth = screen.width;
    window.addEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.screenWidth = screen.width;
  }

  IsMobile() {
    return this.screenWidth <= 950;
  }
}
