import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"socialPage"},[_c('Title',{attrs:{"title":"Social"}}),_c('div',{staticClass:"socialForm rounded-r-lg"},[_c(VTabs,[_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(" Followers ")],1),_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Following ")],1),_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Friends ")],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"overline"},[_vm._v("Users following you.")]),(_vm.profileData.followers)?_c('div',[_c('span',{staticClass:"overline",staticStyle:{"float":"left"}},_vm._l((_vm.profileData.followers),function(user){return _c('div',{key:`f` + user.id},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","outlined":""}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,{staticClass:"profileAvatar",attrs:{"size":"80"}},[_c('router-link',{attrs:{"to":{
                            path: `/profile?id=${user.accountId}`,
                          }}},[(user.accountAvatar == 'default.png')?_c('img',{staticClass:"profilePic",attrs:{"src":require("../assets/images/acc_circle.png")}}):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.apiUri + '/avatars/' + user.accountAvatar}})])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_c('router-link',{attrs:{"to":{
                              path: `/profile?id=${user.accountId}`,
                            }}},[_vm._v(_vm._s(user.accountName))])],1)],1)],1)],1)],1)}),0)]):_c('div',[_c('span',{staticClass:"overline",staticStyle:{"float":"left"}},[_vm._v("No Followers yet.")]),_c('br')])])],1)],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"overline"},[_vm._v("Users you are following.")]),(_vm.profileData.following)?_c('div',[_c('span',{staticClass:"overline",staticStyle:{"float":"left"}},_vm._l((_vm.profileData.following),function(user){return _c('div',{key:`ff` + user.id},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","outlined":""}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,{staticClass:"profileAvatar",attrs:{"size":"80"}},[_c('router-link',{attrs:{"to":{
                            path: `/profile?id=${user.accountId}`,
                          }}},[(user.accountAvatar == 'default.png')?_c('img',{staticClass:"profilePic",attrs:{"src":require("../assets/images/acc_circle.png")}}):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.apiUri + '/avatars/' + user.accountAvatar}})])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_c('router-link',{attrs:{"to":{
                              path: `/profile?id=${user.accountId}`,
                            }}},[_vm._v(_vm._s(user.accountName))])],1)],1)],1)],1)],1)}),0)]):_c('div',[_c('span',{staticClass:"overline",staticStyle:{"float":"left"}},[_vm._v("Not following anyone.")]),_c('br')])])],1)],1),_c(VTabItem,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"overline"},[_vm._v("Users that you follow, that are also following you.")]),(_vm.friends)?_c('div',[_c('span',{staticClass:"overline",staticStyle:{"float":"left"}},_vm._l((_vm.friends),function(user){return _c('div',{key:`u` + user.accountId},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"344","outlined":""}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,{staticClass:"profileAvatar",attrs:{"size":"80"}},[_c('router-link',{attrs:{"to":{
                            path: `/profile?id=${user.accountId}`,
                          }}},[(user.accountAvatar == 'default.png')?_c('img',{staticClass:"profilePic",attrs:{"src":require("../assets/images/acc_circle.png")}}):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.apiUri + '/avatars/' + user.accountAvatar}})])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_c('router-link',{attrs:{"to":{
                              path: `/profile?id=${user.accountId}`,
                            }}},[_vm._v(_vm._s(user.accountName))])],1),_c(VListItemSubtitle,[_vm._v("Friends since "+_vm._s(new Date( user.followedTimestamp ).toLocaleDateString("de-DE", _vm.timeOptions)))])],1)],1)],1)],1)}),0)]):_c('div',[_c('span',{staticClass:"overline",staticStyle:{"float":"left"}},[_vm._v("No friends yet. "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help ")])]}}])},[_c('span',[_vm._v("Friends are automatically added once you follow each other.")])])],1),_c('br')])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }