import axios, { AxiosError, AxiosResponse } from "axios";
import { VueCookies } from "vue-cookies";

export abstract class LoginService {
    private static accountInfo: any;
    public static appConfig = require("../config.json");
    public static apiUri = LoginService.appConfig.apiServerUri
        ? LoginService.appConfig.apiServerUri
        : "" + window.location.origin;

    public static IsLoggedIn(cookies: any) {
        this.accountInfo = cookies.get("accountInfo");
        if (this.accountInfo !== null && this.accountInfo !== undefined) {
            return this.accountInfo;
        }
        return false;
    }

    public static GetAccountInfo(cookies: any): any {
        return cookies.get("accountInfo");
    }

    public static GetAccountId(cookies: any): any {
        this.accountInfo = cookies.get("accountInfo");
        return (this.accountInfo !== null) ? this.accountInfo.accountId : -1;
    }

    public static GetSessionCode(cookies: any): string {
        this.accountInfo = cookies.get("accountInfo");
        return (this.accountInfo !== null) ? this.accountInfo.sessionCode : "";
    }

    public static Logout(cookies: any, router: any) {
        this.accountInfo = cookies.get("accountInfo");
        axios.post(this.apiUri + "/api/user/logout", {
            accountId: this.accountInfo.accountId,
            sessionCode: this.accountInfo.sessionCode,
        });

        cookies.remove("accountInfo");
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("safari") != -1) {
            window.location.reload();
        } else {
            router.go(0);
        }
    }
}