
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import Title from "../components/title/title.vue";
import { LoginService } from "../services/loginService";

@Component({
  components: {
    Title,
  },
})
export default class Social extends Vue {
  appConfig = require("../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  profileData: any = {};
  friends: any = false;

  timeOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  created() {
    if (!LoginService.IsLoggedIn(this.$cookies)) {
      this.$router.push("/");
    }
  }

  mounted() {
    this.fetchProfileData();
    this.fetchFriends();
  }

  fetchProfileData() {
    axios
      .get(this.apiUri + "/api/user", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          profileId: LoginService.GetAccountId(this.$cookies),
        },
      })
      .then(this.userInfoResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  userInfoResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.profileData = response.data.DATA;
        return;
      }
    }
  }

  fetchFriends() {
    axios
      .get(this.apiUri + "/api/friends", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          sourceAccountId: LoginService.GetAccountId(this.$cookies),
        },
      })
      .then(this.fetchFriendsResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  fetchFriendsResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.friends = response.data.DATA;
        return;
      }
    }
  }

  axiosError(error: AxiosError<any>) {}
}
