import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bottombar",attrs:{"id":"bottombar"}},[_c(VBottomNavigation,{attrs:{"fixed":"","color":"#8ab83a"}},[_c(VBtn,{attrs:{"to":{
        path: `/profile`,
      }}},[(!_vm.accountInfo || _vm.accountInfo.avatar == 'default.png')?_c(VIcon,[_vm._v("mdi-account-circle")]):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.apiUri + '/avatars/' + _vm.accountInfo.avatar}})],1),_c(VBtn,{attrs:{"to":{
        path: `/`,
      }}},[_c('span',[_vm._v("Home")]),_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-home")])],1),_c(VBtn,{attrs:{"to":{
        path: `/search`,
      }}},[_c('span',[_vm._v("Search")]),_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-magnify")])],1),_c(VBtn,{attrs:{"to":{
        path: `/lists`,
      }}},[_c('span',[_vm._v("Lists")]),_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-view-list")])],1),_c(VBtn,{attrs:{"to":{
        path: `/social`,
      }}},[_c('span',[_vm._v("Social")]),_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-account-group")])],1),_c(VBtn,{attrs:{"to":{
        path: `/settings`,
      }}},[_c('span',[_vm._v("Settings")]),_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-cog")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }