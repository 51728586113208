import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"showPage"},[(_vm.showInfo)?_c('span',{staticClass:"seriesname"},[_vm._v(_vm._s(_vm.showInfo.name ? _vm.showInfo.name : "N/A"))]):_c(VSkeletonLoader,{attrs:{"type":"text"}}),_c('hr'),_c('br'),_c('div',{staticClass:"infoBox rounded-r-lg"},[(_vm.showInfo)?_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":_vm.showInfo.imageOriginal}})]):_c(VSkeletonLoader,{attrs:{"type":"image"}}),(_vm.showInfo)?_c('div',{staticClass:"seriesInfos"},[_c('span',{staticClass:"infoTag"},[_vm._v("Premiered: ")]),_vm._v(" "+_vm._s(_vm.showInfo.premiered ? new Date(_vm.showInfo.premiered).toLocaleDateString( "de-DE", _vm.premieredDateTimeOptions ) : "N/A")+" "),_c('br'),_c('span',{staticClass:"infoTag"},[_vm._v("Genres: ")]),_vm._l((_vm.showInfo.genres),function(item){return _c('span',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),_c('br'),_c('span',{staticClass:"infoTag"},[_vm._v("Status: ")]),_vm._v(" "+_vm._s(_vm.showInfo.status ? _vm.showInfo.status : "N/A")+" "),_c('br'),_c('span',{staticClass:"infoTag"},[_vm._v("Official Site: ")]),_c('a',{attrs:{"href":_vm.showInfo.officialSite ? _vm.showInfo.officialSite : `N/A`}},[_vm._v(_vm._s(_vm.showInfo.officialSite ? _vm.showInfo.officialSite .replace(`http://`, ``) .replace(`https://`, ``) .replace(`www.`, ``) .split(/[/?#]/)[0] .toUpperCase() : `N/A`))]),_c('br'),_c('span',{staticClass:"infoTag"},[_vm._v("IMDb: ")]),_c('a',{attrs:{"href":_vm.showInfo.imdbCode
            ? `https://www.imdb.com/title/` + _vm.showInfo.imdbCode
            : `N/A`}},[_vm._v(" IMDb.com ")]),_c('br'),_c('br'),(_vm.showInfo)?_c('div',{staticClass:"showSummary",domProps:{"innerHTML":_vm._s(_vm.showInfo.summary)}}):_c(VSkeletonLoader,{attrs:{"type":"list-item-three-line"}}),_c(VBtn,{staticClass:"ma-2 addBtn",on:{"click":function($event){_vm.isShowSeen()
            ? _vm.showAction(`unmarkShowAsSeen`)
            : _vm.showAction(`markShowAsSeen`)}}},[(_vm.isMarking)?_c(VProgressCircular,{attrs:{"left":"","size":20,"width":2,"indeterminate":""}}):_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.isShowSeen() ? "mdi-eye-check" : "mdi-eye-off"))]),_vm._v(" "+_vm._s(_vm.isShowSeen() ? "Mark show as unseen" : "Mark show as seen")+" ")],1),_c(VBtn,{staticClass:"addBtn",on:{"click":function($event){_vm.showInfo.isFavorite
            ? _vm.removeShowFromFavorites()
            : _vm.addShowToFavorites()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.showInfo.isFavorite ? "mdi-heart" : "mdi-heart-outline"))]),_vm._v(" "+_vm._s(_vm.showInfo.isFavorite ? "Remove favorite" : "Add favorite")+" ")],1)],2):_c(VSkeletonLoader,{attrs:{"type":"list-item-three-line"}})],1),_c('br'),(_vm.showInfo)?_c('div',{staticClass:"addToListContainer"},[_c(VSelect,{attrs:{"items":_vm.showLists,"item-text":"name","item-value":"id","label":"Select a list."},model:{value:(_vm.selectedListId),callback:function ($$v) {_vm.selectedListId=$$v},expression:"selectedListId"}}),_c(VBtn,{staticClass:"addBtn",on:{"click":_vm.addShowToList}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-view-list ")]),_vm._v(" Add to list ")],1)],1):_vm._e(),_c('br'),(_vm.showInfo)?_c('div',{staticClass:"seasonButtons"},[_c(VBtn,{on:{"click":function($event){return _vm.changeSeasonData(-1)}}},[_vm._v("ALL EPISODES")]),_vm._l((_vm.showInfo.seasons),function(season,index){return _c(VBtn,{key:season.id,on:{"click":function($event){return _vm.changeSeasonData(index)}}},[_vm._v(_vm._s(season.name !== "" ? season.name + " (S" + season.number + ")" : "Season " + season.number))])})],2):_vm._e(),(_vm.showInfo)?_c('div',{staticClass:"episodeList"},_vm._l((_vm.seasonData),function(season,i){return _c('div',{key:season.id},[_vm._v(" "+_vm._s(season.name !== "" ? season.name : "Season " + season.number)+" "),_c('br'),_c(VBtn,{on:{"click":function($event){_vm.isSeasonSeen(season.id)
            ? _vm.seasonAction(`unmarkSeasonAsSeen`, season.id)
            : _vm.seasonAction(`markSeasonAsSeen`, season.id)}}},[(_vm.isMarking)?_c(VProgressCircular,{attrs:{"left":"","size":20,"width":2,"indeterminate":""}}):_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.isSeasonSeen(season.id) ? "mdi-eye-check" : "mdi-eye-off"))]),_vm._v(" "+_vm._s(_vm.isSeasonSeen(season.id) ? "Mark season as unseen" : "Mark season as seen")+" ")],1),_c(VSimpleTable,{staticClass:"seasonTable",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Episode")]),_c('th',{staticClass:"text-left"},[_vm._v("Episode Title")]),_c('th',{staticClass:"text-left"},[_vm._v("Airdate")]),_c('th',{staticClass:"text-left"},[_vm._v("Plotsummary")]),_c('th',{staticClass:"text-left"},[_vm._v("Seen?")])])]),(_vm.seasonData[i].episodes.length > 0)?_c('tbody',_vm._l((_vm.seasonData[i].episodes),function(episode){return _c('tr',{key:episode.id},[_c('td',[(episode.episodeNumber)?[_c('span',{staticClass:"epHide"},[_vm._v("Episode")]),_vm._v(" "+_vm._s(episode.episodeNumber)+" ")]:[_vm._v(" Special ")]],2),_c('td',[_vm._v(_vm._s(episode.name))]),_c('td',[_vm._v(" "+_vm._s(new Date(episode.airstamp).toLocaleDateString( "de-DE", _vm.premieredDateTimeOptions ))+" ")]),_c('td',{staticClass:"summarySpoiler",domProps:{"innerHTML":_vm._s(episode.summary)}}),_c('td',{staticClass:"episodeSeenIndicator"},[_c(VBtn,{staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){episode.hasSeen
                      ? _vm.episodeAction(`unmarkEpisodeAsSeen`, episode.id)
                      : _vm.episodeAction(`markEpisodeAsSeen`, episode.id)}}},[(_vm.isMarking)?_c(VProgressCircular,{attrs:{"left":"","size":20,"width":2,"indeterminate":""}}):_c(VIcon,{class:episode.hasSeen ? `episodeSeen` : `episodeNotSeen`},[_vm._v(_vm._s(episode.hasSeen ? "mdi-eye-check" : "mdi-eye-off"))])],1)],1)])}),0):_c('div',{staticClass:"noEpisodes"},[_vm._v(" No episodes for this season available. ")])]},proxy:true}],null,true)})],1)}),0):_vm._e(),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"#8ab83a","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }