
import { Component, Vue } from "vue-property-decorator";

const TitleProps = Vue.extend({
  props: {
    title: String,
  },
});

@Component({
  components: {},
})
export default class Title extends TitleProps {}
