import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        dark: true,
        themes: {
            dark: {
                primary: '#8ab83a',
                background: '#1e1e1e',
                text: '#f2f2f2'
            },
            light: {
                primary: '#8ab83a',
                background: '#f2f2f2',
                text: '#000000'
            }
        }
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
