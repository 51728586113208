
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import { LoginService } from "../../services/loginService";

const SidebarProps = Vue.extend({
  props: {},
});

@Component({
  components: {},
})
export default class Sidebar extends SidebarProps {
  appConfig = require("../../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  isExpanded: boolean = false;
  sidebarElement: HTMLElement | null = document.getElementById("showtracker");
  accountInfo: any = null;

  created() {
    this.pingBackend();
  }

  pingBackend() {
    this.accountInfo = LoginService.GetAccountInfo(this.$cookies);
    if (this.accountInfo != null) {
      axios
        .get(this.apiUri + "/api/ping", {
          params: {
            accountId: LoginService.GetAccountId(this.$cookies),
            sessionCode: LoginService.GetSessionCode(this.$cookies),
          },
        })
        .then(this.pingResponse.bind(this))
        .catch(this.axiosError.bind(this));
    }
  }

  pingResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
      } else if (response.data.STATUS == "FAILURE") {
        this.logout();
      }
    }
  }

  axiosError(error: AxiosError<any>) {
    this.logout();
  }

  navigate(urlTarget: string) {
    this.$router.push(urlTarget).catch(() => {});
  }

  toggleSidebar() {
    this.mini = !this.mini;
  }

  get isLoggedIn() {
    this.accountInfo = LoginService.IsLoggedIn(this.$cookies);
    return this.accountInfo;
  }

  logout() {
    LoginService.Logout(this.$cookies, this.$router);
  }

  get mini() {
    return !this.isExpanded;
  }

  set mini(isMini: any) {
    let newWidth: string = "";
    this.sidebarElement = document.getElementById("showtracker");

    if (isMini) {
      newWidth = "56px";
    } else {
      newWidth = "256px";
    }

    if (this.sidebarElement !== null) {
      if (newWidth == "56px") {
        this.sidebarElement.style.gridTemplateColumns =
          newWidth + " calc(100% -  " + newWidth + ")";
      } else {
        this.sidebarElement.style.gridTemplateColumns = newWidth;
      }
    }

    this.isExpanded = !isMini;
  }

  data() {
    return {};
  }
}
