
//import * as config from "../../config.json";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
//import { VueReCaptcha } from "vue-recaptcha-v3";

//Vue.use(VueReCaptcha, {
//siteKey: config.recaptcha.siteKey,
//});

@Component({})
export default class Login extends Vue {
  appConfig = require("../../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  overlay: boolean = false;
  nickname: string = "";
  password: string = "";

  loginNicknameError: string = "";
  loginPasswordError: string = "";

  registerNickname: string = "";
  registerEmail: string = "";
  registerPassword: string = "";
  registerPasswordRepeat: string = "";

  registerNicknameError: string = "";
  registerEmailError: string = "";
  registerPasswordError: string = "";
  registerPasswordRepeatError: string = "";

  loginErrorDsp: boolean = false;
  registerErrorDsp: boolean = false;
  registerSuccess: boolean = false;

  loginErrorMsg: string = "";
  registerErrorMsg: string = "";
  registerSuccessMsg: string = "";

  $refs!: {
    form: HTMLFormElement;
  };

  data() {
    return {
      valid: false,
      overlay: false,

      nickname: "",
      loginNicknameError: "",
      password: "",
      loginPasswordError: "",

      registerNickname: "",
      registerNicknameError: "",

      registerEmail: "",
      registerEmailError: "",

      registerPassword: "",
      registerPasswordError: "",

      registerPasswordRepeat: "",
      registerPasswordRepeatError: "",

      registerErrorDsp: false,
      registerSuccess: false,
      registerErrorMsg: "",
      registerSuccessMsg: "",

      loginErrorDsp: false,
      loginErrorMsg: "",
    };
  }

  async submitLogin(ev: Event) {
    if (!this.$refs.form.validate()) {
      return;
    }

    if (this.nickname == "") {
      this.loginNicknameError = "Username can't be empty.";
      return;
    }

    if (this.password == "") {
      this.loginPasswordError = "Password can't be empty.";
      return;
    }

    this.overlay = true;
    axios
      .post(this.apiUri + "/api/user/login", {
        username: this.nickname,
        password: this.password,
      })
      .then(this.loginResponse.bind(this))
      .catch(this.loginError.bind(this));
  }

  loginResponse(response: AxiosResponse<any>) {
    this.overlay = false;
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        let responseData = response.data.DATA;
        if (
          responseData.accountId &&
          responseData.sessionCode &&
          responseData.validUntil
        ) {
          this.$cookies.set(
            "accountInfo",
            {
              accountId: responseData.accountId,
              username: responseData.username,
              avatar: responseData.avatar,
              sessionCode: responseData.sessionCode,
            },
            new Date(responseData.validUntil)
          );
          var ua = navigator.userAgent.toLowerCase();
          if (ua.indexOf("safari") != -1) {
            window.location.reload();
          } else {
            this.$router.go(0);
          }
        } else {
          this.showLoginError(
            "Sorry, something went wrong. (Didn't recieve valid login response.)"
          );
        }
      } else {
        this.showLoginError(response.data.MESSAGE);
      }
    } else {
      this.showLoginError("Something went wrong!");
    }
  }

  loginError(error: AxiosError<any>) {
    this.overlay = false;
    this.showLoginError(error.message);
  }

  showLoginError(message: string) {
    this.loginErrorMsg = message;
    this.loginErrorDsp = true;
  }

  async submitRegister(ev: Event) {
    if (!this.$refs.form.validate()) {
      return;
    }

    if (this.registerNickname == "") {
      this.registerNicknameError = "Username can't be empty.";
      return;
    } else {
      this.registerNicknameError = "";
    }

    if (this.registerEmail == "") {
      this.registerEmailError = "E-Mail address can't be empty.";
      return;
    } else {
      if (/\S+@\S+\.\S+/.test(this.registerEmail)) {
        this.registerEmailError = "";
      } else {
        this.registerEmailError =
          "Invalid E-Mail address! (Format: user@domain.tld)" +
          this.registerEmail;
        return;
      }
    }

    if (this.registerPassword == "") {
      this.registerPasswordError = "Password can't be empty.";
      return;
    } else {
      this.registerPasswordError = "";
    }

    if (this.registerPasswordRepeat == "") {
      this.registerPasswordRepeatError = "Password can't be empty.";
      return;
    } else {
      this.registerPasswordRepeatError = "";
    }

    if (this.registerPassword != this.registerPasswordRepeat) {
      this.registerErrorMsg = "Passwords don't match.";
      this.registerErrorDsp = true;
      this.registerSuccess = false;

      return;
    }

    this.overlay = true;
    axios
      .post(this.apiUri + "/api/user", {
        name: this.registerNickname,
        email: this.registerEmail,
        password: this.registerPassword,
      })
      .then(this.registerResponse.bind(this))
      .catch(this.registerError.bind(this));
  }

  registerResponse(response: AxiosResponse<any>) {
    this.overlay = false;
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        this.showRegisterSuccess(response.data.MESSAGE);
      } else {
        this.showRegisterError(response.data.MESSAGE);
      }
    } else {
      this.showRegisterError("Sorry, something went wrong!");
    }

    return;
  }
  registerError(error: AxiosError<any>) {
    this.overlay = false;
    this.showRegisterError(error.message);
    return;
  }

  showRegisterSuccess(message: string) {
    this.registerSuccessMsg =
      "Account successfully created! :) You can now log in.";
    this.registerSuccess = true;
  }
  showRegisterError(message: string) {
    this.registerErrorMsg = message;
    this.registerErrorDsp = true;
  }

  keydown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      //event.preventDefault();
      //this.submitLogin(event);
    } else {
    }
  }
}
