
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import Title from "../components/title/title.vue";
import { LoginService } from "../services/loginService";

@Component({
  components: {
    Title,
  },
})
export default class Search extends Vue {
  appConfig = require("../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  follows: any = {};
  searchTab: string | (string | null)[] = "";
  searchInput: string = "";
  showResults: any[] = [];
  userResults: any[] = [];
  listResults: any[] = [];
  timeOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  snackbar = false;
  snackbarText = "";

  created() {
    if (!LoginService.IsLoggedIn(this.$cookies)) {
      this.$router.push("/");
    }
  }

  data() {
    return {
      searchRules: [
        (value: string) => !!value || "Search string can't be empty!",
        (value: string) =>
          value.length >= 3 || "Search string must be at least 3 characters.",
      ],
      showResults: "",
    };
  }

  get activeSearchTab() {
    return this.$route.query.t;
  }
  set activeSearchTab(t) {
    this.searchTab = t;
    this.$router.replace({
      query: { ...this.$route.query, t },
    });
  }

  search() {
    if (this.searchInput && this.searchInput.length >= 3) {
      switch (this.searchTab) {
        case "shows":
          //Shows
          this.searchShows();
          break;
        case "users":
          //Users
          this.fetchFollows();
          this.searchUsers();
          break;
        case "lists":
          //Lists
          this.searchLists();
          break;
        default:
          break;
      }
    }
  }

  fetchFollows() {
    axios
      .get(this.apiUri + "/api/follows", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          sourceAccountId: LoginService.GetAccountId(this.$cookies),
        },
      })
      .then(this.fetchFollowsResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  fetchFollowsResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.follows = response.data.DATA;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  searchShows() {
    axios
      .get(this.apiUri + "/api/search/shows", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          query: this.searchInput,
        },
      })
      .then(this.showSearchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  showSearchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.showResults = response.data.DATA;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  searchUsers() {
    axios
      .get(this.apiUri + "/api/search/users", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          query: this.searchInput,
        },
      })
      .then(this.userSearchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  userSearchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.userResults = response.data.DATA;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  searchLists() {
    axios
      .get(this.apiUri + "/api/search/userlists", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          query: this.searchInput,
        },
      })
      .then(this.searchListsResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  searchListsResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.listResults = response.data.DATA;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  GetAccountId() {
    return LoginService.GetAccountId(this.$cookies);
  }

  IsFollowing(id: number) {
    if (this.follows !== undefined) {
      for (var f of this.follows) {
        if (f.accountId == id) return true;
      }
    }
    return false;
  }

  FollowButtonClick(id: number) {
    if (!this.IsFollowing(id)) {
      axios
        .post(this.apiUri + "/api/follows", {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          targetAccountId: id,
        })
        .then(this.addFollowResponse.bind(this))
        .catch(this.axiosError.bind(this));
    } else {
      axios
        .delete(this.apiUri + "/api/follows", {
          data: {
            accountId: LoginService.GetAccountId(this.$cookies),
            sessionCode: LoginService.GetSessionCode(this.$cookies),
            targetAccountId: id,
          },
        })
        .then(this.removeFollowResponse.bind(this))
        .catch(this.axiosError.bind(this));
    }
  }

  addFollowResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.follows.push({
          accountId: response.data.DATA.id,
          accountName: "",
          avatar: "",
        });
        this.snackbarText = "Successfully followed! :)";
        this.snackbar = true;
        return;
      }
    }
  }

  removeFollowResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.follows = this.follows.filter((el: any) => {
          return el.accountId != response.data.DATA.id;
        });

        this.snackbarText = "Stopped following.";
        this.snackbar = true;
        return;
      }
    }
  }

  axiosError(error: AxiosError<any>) {}
}
