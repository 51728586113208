
import Login from "@/components/login/login.vue";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";
import Title from "../components/title/title.vue";
import { LoginService } from "../services/loginService";

@Component({
  components: {
    Title,
  },
})
export default class Profile extends Vue {
  appConfig = require("../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  accountId: number = -1;
  profileData: any = {};

  profileXP: number = 0;
  profileLevel: number = 0;
  profileProgress: number = 0;

  avatarWindow: any = false;
  overlay: any = false;

  activityFeed: any = [];
  favoriteShows: any = [];

  psFavOptions: any = {
    suppressScrollY: true,
    useBothWheelAxes: true,
  };

  psFollowingOptions: any = {
    suppressScrollY: true,
    useBothWheelAxes: true,
  };

  registerDateTimeOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  selectedFile: any = "";

  snackbar = false;
  snackbarText = "";

  onAvatarChange(file: any) {
    this.selectedFile = file;
  }

  uploadAvatar() {
    let formData = new FormData();
    formData.append("accountId", LoginService.GetAccountId(this.$cookies));
    formData.append("sessionCode", LoginService.GetSessionCode(this.$cookies));
    formData.append("file", this.selectedFile);
    axios
      .post(this.apiUri + "/api/user/avatar", formData)
      .then(this.uploadAvatarResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  uploadAvatarResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.avatarWindow = false;

        this.profileData.avatar = response.data.DATA.name;
        let accountInfo = LoginService.GetAccountInfo(this.$cookies);
        this.$cookies.set(
          "accountInfo",
          {
            accountId: accountInfo.accountId,
            username: accountInfo.username,
            avatar: response.data.DATA.name,
            sessionCode: accountInfo.sessionCode,
          },
          new Date(accountInfo.validUntil)
        );
        return;
      }
    }
  }

  GetProfileRank(level: number) {
    if (level < 5) {
      return ["Rookie", "#FF00FF"];
    } else if (level >= 5 && level < 10) {
      return ["Pro", "#00FF00"];
    } else {
      return ["Binge Watcher", "#8ab83a"];
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: any, oldVal: any) {
    if (oldVal !== undefined && newVal.fullPath != oldVal.fullPath) {
      this.fetchData();
    }
  }

  created() {
    if (!LoginService.IsLoggedIn(this.$cookies)) {
      this.$router.push("/");
    } else {
      this.fetchData();
    }
  }

  fetchData() {
    let id = this.$route.query.id;
    if (id != null) {
      this.accountId = parseInt(id.toString());
    } else {
      this.accountId = this.GetAccountId();
    }

    axios
      .get(this.apiUri + "/api/user", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          profileId: this.accountId,
        },
      })
      .then(this.userInfoResponse.bind(this))
      .catch(this.axiosError.bind(this));

    axios
      .get(this.apiUri + "/api/user/activityfeed", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          targetAccountId: this.accountId,
        },
      })
      .then(this.activityFeedResponse.bind(this))
      .catch(this.axiosError.bind(this));

    axios
      .get(this.apiUri + "/api/user/favorites", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          targetAccountId: this.accountId,
          limit: 100,
        },
      })
      .then(this.favoritesResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  userInfoResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.profileData = response.data.DATA;
        this.profileXP = this.profileData.experience;
        this.profileLevel = Math.floor(this.profileXP / 1000);
        this.profileProgress =
          (this.profileXP - this.profileLevel * 1000) * 0.1;
        return;
      }
    }
  }

  activityFeedResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.activityFeed = response.data.DATA;
        return;
      }
    }
  }

  favoritesResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.favoriteShows = response.data.DATA;
        return;
      }
    }
  }

  axiosError(error: AxiosError<any>) {}

  mounted() {}

  avatarHover() {
    if (this.GetAccountId() == this.accountId) {
      this.overlay = true;
    }
  }
  avatarStopHover() {
    this.overlay = false;
  }

  showAvatarUpload() {
    if (this.GetAccountId() == this.accountId) {
      this.avatarWindow = true;
    }
  }

  GetAccountId() {
    return LoginService.GetAccountId(this.$cookies);
  }

  IsFollowing() {
    if (
      this.profileData !== undefined &&
      this.profileData.followers !== undefined
    ) {
      for (var f of this.profileData.followers) {
        if (f.accountId == this.GetAccountId()) return true;
      }
    }
    return false;
  }

  FollowButtonClick() {
    if (!this.IsFollowing()) {
      axios
        .post(this.apiUri + "/api/follows", {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          targetAccountId: this.accountId,
        })
        .then(this.addFollowResponse.bind(this))
        .catch(this.axiosError.bind(this));
    } else {
      axios
        .delete(this.apiUri + "/api/follows", {
          data: {
            accountId: LoginService.GetAccountId(this.$cookies),
            sessionCode: LoginService.GetSessionCode(this.$cookies),
            targetAccountId: this.accountId,
          },
        })
        .then(this.removeFollowResponse.bind(this))
        .catch(this.axiosError.bind(this));
    }
  }

  addFollowResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        let ownInfo: any = LoginService.GetAccountInfo(this.$cookies);
        this.profileData.followers.push({
          accountId: ownInfo.accountId,
          accountName: ownInfo.username,
          accountAvatar: ownInfo.avatar,
        });
        this.snackbarText =
          "You are now following " + this.profileData.accountName + "! :)";
        this.snackbar = true;
        return;
      }
    }
  }

  removeFollowResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.profileData.followers = this.profileData.followers.filter(
          (el: any) => {
            return el.accountId != LoginService.GetAccountId(this.$cookies);
          }
        );

        this.snackbarText =
          "You are no longer following " + this.profileData.accountName + ".";
        this.snackbar = true;
        return;
      }
    }
  }
}
