
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import Title from "../components/title/title.vue";
import LastSeenSelector from "../components/progressUpdate/lastSeenSelector.vue";
import StatusSelector from "../components/progressUpdate/statusSelector.vue";
import { LoginService } from "../services/loginService";

@Component({
  components: { Title, LastSeenSelector, StatusSelector },
})
export default class Home extends Vue {
  appConfig = require("../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  statusTags: any = ["Planned", "Paused", "OnGoing", "Completed", "Dropped"];
  statusTagColors: any = [
    "grey",
    "blue darken-3",
    "green darken-2",
    "green darken-4",
    "red darken-4",
  ];

  timeOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  listPanels: any = [];
  progressPanels: any = [[]];

  showLists: any = [];
  showListEntries: any = [];
  showListCollaborators: any = [];

  selectedCollaboratorName: any[] = [];
  friends: any[] = [];

  addListInput: string = "";
  listRules: any = [(value: string) => !!value || "List name can't be empty!"];

  isUpdatingProgress: boolean = false;
  seasonsForLastSeen: any[] = [];
  progressIdForLastSeen: number = -1;

  isUpdatingStatus: boolean = false;
  progressIdForStatus: number = -1;

  collaboratorHeaders: any = [
    {
      text: "Collaborator",
      align: "start",
      sortable: true,
      value: "collaboratorAccountId",
    },
    { text: "Last seen", value: "lastSeenEpisode" },
    { text: "Status", value: "status" },
    { text: "", value: "delete" },
  ];

  singleListPage: any = undefined;

  snackbar = false;
  snackbarText = "";

  created() {
    if (!LoginService.IsLoggedIn(this.$cookies)) {
      this.$router.push("/");
    } else {
      this.fetchFriends();
      this.fetchListData();
    }
  }

  fetchListData() {
    this.singleListPage = this.$route.query.list;
    if (this.singleListPage === undefined) {
      this.listFetch();
    } else {
      this.singleListFetch(this.singleListPage);
    }
  }

  GetAccountId() {
    return LoginService.GetAccountId(this.$cookies);
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: any, oldVal: any) {
    if (oldVal !== undefined && newVal.fullPath !== oldVal.fullPath) {
      this.fetchListData();
    }
  }

  displaySnackbar(message: string) {
    this.snackbarText = message;
    this.snackbar = true;
  }

  stopUpdatingProgress(reload: boolean) {
    this.isUpdatingProgress = false;
    if (reload) {
      if (this.singleListPage === undefined) {
        this.listFetch();
      } else {
        this.singleListFetch(this.singleListPage);
      }
    }
  }

  stopUpdatingStatus() {
    this.isUpdatingStatus = false;
    if (this.singleListPage === undefined) {
      this.listFetch();
    } else {
      this.singleListFetch(this.singleListPage);
    }
  }

  listFetch() {
    let accountId = LoginService.GetAccountId(this.$cookies);
    axios
      .get(this.apiUri + "/api/list", {
        params: {
          accountId: accountId,
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listAccountId: accountId,
          type: "all",
        },
      })
      .then(this.listFetchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  listFetchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.showLists = {};
        this.showListEntries = [];
        this.showLists = response.data.DATA;

        for (var list of this.showLists) {
          this.fetchListEntries(list.id);
        }
        return;
      }
    }
  }

  singleListFetch(listId: number) {
    let accountId = LoginService.GetAccountId(this.$cookies);
    axios
      .get(this.apiUri + "/api/list", {
        params: {
          accountId: accountId,
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listAccountId: accountId,
          type: "single",
          listId: listId,
        },
      })
      .then(this.singleListFetchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  singleListFetchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.showLists = {};
        Vue.set(this.showLists, 0, response.data.DATA);
        this.fetchListEntries(this.showLists[0].id);
        return;
      }
    }
  }

  fetchListEntries(listId: number) {
    axios
      .get(this.apiUri + "/api/list/entry", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listId: listId,
        },
      })
      .then(this.listEntriesFetchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  listEntriesFetchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        if (response.data.DATA !== null) {
          Vue.set(this.showListEntries, this.showListEntries.length, {
            listId: response.data.DATA.listId,
            shows: response.data.DATA.shows,
          });
          for (var dataColl of response.data.DATA.collaborators) {
            Vue.set(this.showListCollaborators, dataColl.accountId, dataColl);
          }
        }
        return;
      }
    }
  }

  GetShowListEntries(listId: number) {
    for (var entry of this.showListEntries) {
      if (entry.listId == listId) {
        return entry.shows;
      }
    }
  }

  addList() {
    if (this.addListInput) {
      axios
        .post(this.apiUri + "/api/list", {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listName: this.addListInput,
        })
        .then(this.addListRepsonse.bind(this))
        .catch(this.axiosError.bind(this));
    }
  }

  addListRepsonse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.listFetch();
        this.displaySnackbar("Successfully added list.");
        return;
      }
    }
  }

  deleteList(listId: number) {
    axios
      .delete(this.apiUri + "/api/list", {
        data: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listId: listId,
        },
      })
      .then(this.deleteListResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  deleteListResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        if (this.singleListPage === undefined) {
          this.listFetch();
        } else {
          this.$router.push(`/lists`);
          this.$router.go(0);
        }

        this.displaySnackbar("Successfully removed list.");
        return;
      }
    }
  }

  deleteShowFromList(showId: number, listId: number) {
    axios
      .delete(this.apiUri + "/api/list/entry", {
        data: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listId: listId,
          showId: showId,
        },
      })
      .then(this.deleteShowFromListResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  deleteShowFromListResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        if (this.singleListPage === undefined) {
          this.listFetch();
        } else {
          this.$router.push(`/lists`);
          this.$router.go(0);
        }

        this.displaySnackbar("Successfully removed show from list.");
        return;
      }
    }
  }

  fetchFriends() {
    axios
      .get(this.apiUri + "/api/friends", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          sourceAccountId: LoginService.GetAccountId(this.$cookies),
        },
      })
      .then(this.fetchFriendsResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  fetchFriendsResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.friends = response.data.DATA;
        return;
      }
    }
  }

  addCollaborator(listId: number) {
    let collaboratorId: number = this.GetFriendIdFromName(
      this.selectedCollaboratorName[listId]
    );
    if (collaboratorId !== undefined) {
      if (!this.IsUserCollaboratorOfList(collaboratorId, listId)) {
        axios
          .post(this.apiUri + "/api/list/collaborator", {
            accountId: LoginService.GetAccountId(this.$cookies),
            sessionCode: LoginService.GetSessionCode(this.$cookies),
            listId: listId,
            collaboratorId: collaboratorId,
          })
          .then(this.addCollaboratorResponse.bind(this))
          .catch(this.axiosError.bind(this));
      }
    }
  }

  addCollaboratorResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        if (this.singleListPage === undefined) {
          this.listFetch();
        } else {
          this.singleListFetch(this.singleListPage);
        }
        this.displaySnackbar("Successfully added collaborator to list.");
        return;
      }
    }
  }

  removeCollaborator(listId: number, collaboratorAccountId: number) {
    if (collaboratorAccountId !== undefined) {
      if (this.IsUserCollaboratorOfList(collaboratorAccountId, listId)) {
        axios
          .delete(this.apiUri + "/api/list/collaborator", {
            data: {
              accountId: LoginService.GetAccountId(this.$cookies),
              sessionCode: LoginService.GetSessionCode(this.$cookies),
              listId: listId,
              collaboratorAccountId: collaboratorAccountId,
            },
          })
          .then(this.removeCollaboratorResponse.bind(this))
          .catch(this.axiosError.bind(this));
      }
    }
  }

  removeCollaboratorResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        if (this.singleListPage === undefined) {
          this.listFetch();
        } else {
          this.singleListFetch(this.singleListPage);
        }
        this.displaySnackbar("Successfully removed collaborator from list.");
        return;
      }
    }
  }

  IsUserCollaboratorOfList(accountId: number, listId: number) {
    let entries = this.GetShowListEntries(listId);
    if (entries !== undefined) {
      if (entries[0] !== undefined) {
        for (var p of entries[0].progresses) {
          if (p.collaboratorAccountId == accountId) return true;
        }
      }
    }
  }

  GetFriendIdFromName(name: string) {
    for (var f of this.friends) {
      if (f.accountName == name) return f.accountId;
    }
  }

  GetNonCollaboratingFriends(listId: number) {
    let returnArray: any[] = [];
    for (var f of this.friends) {
      if (!this.IsUserCollaboratorOfList(f.accountId, listId))
        returnArray.push(f.accountName);
    }
    return returnArray;
  }

  axiosError(error: AxiosError<any>) {}

  getCollaboratorName(collaboratorAccountId: number) {
    return this.showListCollaborators[collaboratorAccountId].accountName;
  }

  getCollaboratorAvatar(collaboratorAccountId: number) {
    return this.showListCollaborators[collaboratorAccountId].avatar;
  }

  updateProgress(targetAccountId: number, progressId: number, seasons: any) {
    let accountId = LoginService.GetAccountId(this.$cookies);
    if (accountId == targetAccountId) {
      this.seasonsForLastSeen = seasons;
      this.progressIdForLastSeen = progressId;
      this.isUpdatingProgress = true;
    }
  }

  updateStatus(targetAccountId: number, progressId: number) {
    let accountId = LoginService.GetAccountId(this.$cookies);
    if (accountId == targetAccountId) {
      this.progressIdForStatus = progressId;
      this.isUpdatingStatus = true;
    }
  }
  expandAll() {
    let p: any = [];
    for (let i = 0; i < this.showLists.length; i++) {
      p.push(i);
    }
    this.listPanels = p;
  }

  collapseAll() {
    this.listPanels = [];
  }

  expandListProgress(listIndex: number, listId: number) {
    let p: any = [];
    for (let i = 0; i < this.GetShowListEntries(listId).length; i++) {
      p.push(i);
    }
    Vue.set(this.progressPanels, listIndex, p);
  }

  collapseListProgress(listIndex: number) {
    Vue.set(this.progressPanels, listIndex, []);
  }
}
