import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Search from '../views/Search.vue'
import Lists from '../views/Lists.vue'
import Social from '../views/Social.vue'
import Showpage from '../views/Showpage.vue'
import Profile from '../views/Profile.vue'
import Settings from '../views/Settings.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/lists',
    name: 'Lists',
    component: Lists
  },
  {
    path: '/social',
    name: 'Social',
    component: Social
  },
  {
    path: '/Showpage',
    name: 'Showpage',
    component: Showpage
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }

]

const router = new VueRouter({
  routes
})

export default router
