import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"searchPage"},[_c('Title',{attrs:{"title":"Search"}}),_c(VTextField,{staticClass:"searchInput",attrs:{"append-icon":"mdi-magnify","label":"Search","type":"text","rules":_vm.searchRules},on:{"click:append":_vm.search,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('div',{staticClass:"searchForm rounded-r-lg"},[_c(VTabs,{attrs:{"fixed-tabs":""},model:{value:(_vm.activeSearchTab),callback:function ($$v) {_vm.activeSearchTab=$$v},expression:"activeSearchTab"}},[_c(VTab,{attrs:{"href":"#shows"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-animation-play")]),_vm._v(" Shows ")],1),_c(VTab,{attrs:{"href":"#users"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-account")]),_vm._v(" Users ")],1),_c(VTab,{attrs:{"href":"#lists"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-format-list-bulleted")]),_vm._v(" Lists ")],1),_c(VTabsItems,{attrs:{"value":_vm.activeSearchTab}},[_c(VTabItem,{attrs:{"value":"shows"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"overline"},[_vm._v("Search results for series.")]),_c('div',{staticClass:"ShowSearchResults"},_vm._l((_vm.showResults),function(show){return _c('div',{key:show.id,staticClass:"ShowSearchEntry"},[_c('div',{staticClass:"ShowSearchEntryThumbnail"},[_c('router-link',{attrs:{"to":{
                        path: `/showpage?s=${show.tvmazeId}`,
                      }}},[_c('img',{attrs:{"src":show.imageOriginal
                            ? show.imageOriginal
                            : require(`@/assets/images/SearchThumbnailPlaceholder.png`)}})])],1),_c('div',{staticClass:"ShowSearchEntryData"},[_c('div',{staticClass:"ShowSearchEntryTitle"},[_c('router-link',{attrs:{"to":{
                          path: `/showpage?s=${show.tvmazeId}`,
                        }}},[_vm._v(_vm._s(show.name))])],1),_c('b',[_vm._v("Premiered: "+_vm._s(new Date(show.premiered).toLocaleDateString( "de-DE", _vm.timeOptions )))]),_c('div',{staticClass:"ShowSearchEntrySummary",domProps:{"innerHTML":_vm._s(show.summary)}})])])}),0)])],1)],1),_c(VTabItem,{attrs:{"value":"users"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"overline"},[_vm._v("Search results for profiles.")]),_c('div',{staticClass:"UserSearchResults"},_vm._l((_vm.userResults),function(user){return _c('div',{key:`u` + user.id},[_c(VCard,{staticClass:"UserSearchEntry",attrs:{"max-width":"344","outlined":""}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,{staticClass:"UserSearchEntryThumbnail",attrs:{"size":"80px"}},[_c('router-link',{attrs:{"to":{
                            path: `/profile?id=${user.id}`,
                          }}},[(user.avatar == 'default.png')?_c('img',{staticClass:"profilePic",attrs:{"src":require("../assets/images/acc_circle.png")}}):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.apiUri + '/avatars/' + user.avatar}})])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_c('router-link',{attrs:{"to":{
                              path: `/profile?id=${user.id}`,
                            }}},[_vm._v(_vm._s(user.username))]),(_vm.GetAccountId() !== user.id)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.FollowButtonClick(user.id)}}},[_c(VIcon,{attrs:{"icon":""}},[_vm._v(" "+_vm._s(_vm.IsFollowing(user.id) ? "mdi-account-minus" : "mdi-account-plus")+" ")])],1):_vm._e()],1),_c(VListItemSubtitle,[_vm._v("Member since "+_vm._s(new Date(user.registerDate).toLocaleDateString( "de-DE", _vm.timeOptions )))])],1)],1)],1)],1)}),0)])],1)],1),_c(VTabItem,{attrs:{"value":"lists"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('span',{staticClass:"overline"},[_vm._v("Search results for lists.")]),_vm._l((_vm.listResults),function(list){return _c('div',{key:`l` + list.id},[_c(VCard,{staticClass:"ListSearchEntry",attrs:{"max-width":"344","outlined":""}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h5 mb-1"},[_c('router-link',{attrs:{"to":{
                            path: `/lists?list=${list.id}`,
                          }}},[_vm._v(_vm._s(list.name))])],1),_c(VListItemSubtitle,[_vm._v("Owner: "),_c('router-link',{attrs:{"to":{
                            path: `/profile?id=${list.ownerAccountId}`,
                          }}},[_vm._v(_vm._s(list.ownerUsername))])],1)],1)],1)],1)],1)})],2)],1)],1)],1)],1)],1),_c(VSnackbar,{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"#8ab83a","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }