
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import Login from "../components/login/login.vue";
import { LoginService } from "../services/loginService";

@Component({
  components: {
    Login,
  },
})
export default class Home extends Vue {
  accountInfo: any = false;
  appConfig = require("../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;
  activityFeed: any = [];
  favoriteShows: any = [];

  scrollbarOptions: any = {
    suppressScrollY: true,
    useBothWheelAxes: true,
  };

  created() {
    let accountInfo = LoginService.GetAccountInfo(this.$cookies);
    if (accountInfo != null) {
      this.fetchHomescreen();
    }
  }

  fetchHomescreen() {
    axios
      .get(this.apiUri + "/api/user/activityfeed", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          targetAccountId: LoginService.GetAccountId(this.$cookies),
        },
      })
      .then(this.activityFeedResponse.bind(this))
      .catch(this.axiosError.bind(this));

    axios
      .get(this.apiUri + "/api/user/favorites", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          targetAccountId: LoginService.GetAccountId(this.$cookies),
        },
      })
      .then(this.favoritesResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  activityFeedResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.activityFeed = response.data.DATA;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  favoritesResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.favoriteShows = response.data.DATA;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  axiosError(error: AxiosError<any>) {}

  get isLoggedIn() {
    this.accountInfo = LoginService.IsLoggedIn(this.$cookies);
    return this.accountInfo;
  }
}
