
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import { LoginService } from "../services/loginService";

@Component({
  components: {},
})
export default class Showpage extends Vue {
  appConfig = require("../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  isMarking: boolean = false;

  snackbar = false;
  snackbarText = "";
  showInfo: any = false;
  seasonData: any = [];
  premieredDateTimeOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  lastAiredEpisode: any = "";
  showLists: any = [];
  selectedListId = -1;

  created() {
    if (!LoginService.IsLoggedIn(this.$cookies)) {
      this.$router.push("/");
    }
  }

  mounted() {
    this.requestShowInfo();
    this.listFetch();
  }

  listFetch() {
    let accountId = LoginService.GetAccountId(this.$cookies);
    axios
      .get(this.apiUri + "/api/list", {
        params: {
          accountId: accountId,
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listAccountId: accountId,
          type: "owned",
        },
      })
      .then(this.listFetchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  listFetchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.showLists = response.data.DATA;
        return;
      }
    }
  }

  requestShowInfo() {
    let tvmazeId = this.$route.query.s;
    axios
      .get(this.apiUri + "/api/show", {
        params: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          tvmazeId: tvmazeId,
        },
      })
      .then(this.showSearchResponse.bind(this))
      .catch(this.axiosError.bind(this));
  }

  showSearchResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
        this.showInfo = response.data.DATA;
        this.seasonData = this.showInfo.seasons;
        return;
      }
    }
    //TODO:: Show error(maybe)
  }

  axiosError(error: AxiosError<any>) {}

  changeSeasonData(index: number) {
    if (index == -1) {
      this.seasonData = this.showInfo.seasons;
    } else {
      this.seasonData = [this.showInfo.seasons[index]];
    }
  }

  episodeAction(action: string, episodeId: number) {
    if (!this.isMarking) {
      this.isMarking = true;
      axios
        .post(this.apiUri + "/api/user/actions", {
          action: action,
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          episodeId: episodeId,
        })
        .then(this.episodeActionResponse.bind(this))
        .catch(this.responseError.bind(this));
    }
  }

  episodeActionResponse(response: AxiosResponse<any>) {
    this.isMarking = false;
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        for (var season of this.showInfo.seasons) {
          for (var episode of season.episodes) {
            if (episode.id === response.data.DATA.episodeId) {
              episode.hasSeen = response.data.DATA.hasSeen;
            }
          }
        }
      } else if (response.data.STATUS === "FAILURE") {
        this.snackbarText = response.data.MESSAGE;
        this.snackbar = true;
      }
    }
    return;
  }

  seasonAction(action: string, seasonId: number) {
    axios
      .post(this.apiUri + "/api/user/actions", {
        action: action,
        accountId: LoginService.GetAccountId(this.$cookies),
        sessionCode: LoginService.GetSessionCode(this.$cookies),
        seasonId: seasonId,
      })
      .then(this.seasonActionResponse.bind(this))
      .catch(this.responseError.bind(this));
    this.isMarking = true;
  }

  seasonActionResponse(response: AxiosResponse<any>) {
    this.isMarking = false;
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        for (var season of this.showInfo.seasons) {
          if (season.id == response.data.DATA.seasonId) {
            for (var episode of season.episodes) {
              if (
                response.data.DATA.markedEpisodes.indexOf(episode.id) !== -1
              ) {
                episode.hasSeen = response.data.DATA.hasSeen;
              }
            }
          }
        }
      }
    }
    return;
  }

  showAction(action: string) {
    axios
      .post(this.apiUri + "/api/user/actions", {
        action: action,
        accountId: LoginService.GetAccountId(this.$cookies),
        sessionCode: LoginService.GetSessionCode(this.$cookies),
        showId: this.showInfo.id,
      })
      .then(this.showActionResponse.bind(this))
      .catch(this.responseError.bind(this));
    this.isMarking = true;
  }

  showActionResponse(response: AxiosResponse<any>) {
    this.isMarking = false;
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        for (var season of this.showInfo.seasons) {
          for (var episode of season.episodes) {
            if (response.data.DATA.markedEpisodes.indexOf(episode.id) !== -1) {
              episode.hasSeen = response.data.DATA.hasSeen;
            }
          }
        }
      }
    }
    return;
  }

  addShowToFavorites() {
    axios
      .post(this.apiUri + "/api/user/favorites", {
        accountId: LoginService.GetAccountId(this.$cookies),
        sessionCode: LoginService.GetSessionCode(this.$cookies),
        showId: this.showInfo.id,
      })
      .then(this.addFavoriteResponse.bind(this))
      .catch(this.responseError.bind(this));
  }

  addFavoriteResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        this.snackbarText = "Added show to favorites. ❤️";
        this.snackbar = true;
        this.showInfo.isFavorite = true;
      }
    }
    return;
  }

  removeShowFromFavorites() {
    axios
      .delete(this.apiUri + "/api/user/favorites", {
        data: {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          showId: this.showInfo.id,
        },
      })
      .then(this.removeFavoriteResponse.bind(this))
      .catch(this.responseError.bind(this));
  }

  removeFavoriteResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        this.snackbarText = "Removed show from favorites.";
        this.snackbar = true;
        this.showInfo.isFavorite = false;
      }
    }
    return;
  }

  addShowToList() {
    if (this.selectedListId != -1) {
      axios
        .post(this.apiUri + "/api/list/entry", {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          listId: this.selectedListId,
          showId: this.showInfo.id,
        })
        .then(this.addShowToListResponse.bind(this))
        .catch(this.responseError.bind(this));
      return;
    } else {
      this.snackbarText = "❗ Please select a list first.";
      this.snackbar = true;
    }
  }

  addShowToListResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS === "SUCCESS") {
        this.snackbarText = "✔️ Added show to list.";
        this.snackbar = true;
      } else if (response.data.STATUS === "FAILURE") {
        this.snackbarText = response.data.MESSAGE;
        this.snackbar = true;
      }
    }
  }

  responseError(error: AxiosError<any>) {}

  isShowSeen() {
    for (var season of this.showInfo.seasons) {
      for (var episode of season.episodes) {
        if (!episode.hasSeen) {
          return false;
        }
      }
    }
    return true;
  }

  isSeasonSeen(seasonId: number) {
    for (var season of this.showInfo.seasons) {
      if (season.id == seasonId) {
        for (var episode of season.episodes) {
          if (!episode.hasSeen) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
