
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import { LoginService } from "../../services/loginService";

@Component({
  components: {},
})
export default class Bottombar extends Vue {
  appConfig = require("../../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  accountInfo: any = null;

  created() {
    this.pingBackend();
  }

  pingBackend() {
    this.accountInfo = LoginService.GetAccountInfo(this.$cookies);
    if (this.accountInfo != null) {
      axios
        .get(this.apiUri + "/api/ping", {
          params: {
            accountId: LoginService.GetAccountId(this.$cookies),
            sessionCode: LoginService.GetSessionCode(this.$cookies),
          },
        })
        .then(this.pingResponse.bind(this))
        .catch(this.axiosError.bind(this));
    }
  }

  pingResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
      if (response.data.STATUS == "SUCCESS") {
      } else if (response.data.STATUS == "FAILURE") {
        this.logout();
      }
    }
  }

  axiosError(error: AxiosError<any>) {
    this.logout();
  }

  get isLoggedIn() {
    this.accountInfo = LoginService.IsLoggedIn(this.$cookies);
    return this.accountInfo;
  }

  logout() {
    LoginService.Logout(this.$cookies, this.$router);
  }
}
