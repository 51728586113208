
import { LoginService } from "@/services/loginService";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Emit, Vue, Watch } from "vue-property-decorator";

const LastSeenSelectorProps = Vue.extend({
  props: {
    visible: Boolean,
    initialSeason: Number,
    seasons: [],
    collaboratorId: Number,
    progressId: Number,
  },
});

@Component({
  components: {},
})
export default class LastSeenSelector extends LastSeenSelectorProps {
  appConfig = require("../../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  scrollbarOptions: any = {
    suppressScrollX: true,
    useBothWheelAxes: true,
  };

  screenWidth = 0;
  selectedSeason: number = -1;
  isVisible = false;

  mounted() {
    this.screenWidth = screen.width;
    window.addEventListener("resize", this.handleResize);
  }

  @Watch("$props.seasons", { immediate: true, deep: true })
  onDataChange(newVal: any, oldVal: any) {
    if (newVal.length > 0) {
      this.selectedSeason = newVal[0].id;
    }
  }

  @Watch("$props.visible")
  onVChange(newVal: any, oldVal: any) {
    this.isVisible = newVal;
  }

  @Watch("isVisible")
  onVisibilityChange(newVal: any, oldVal: any) {
    if (!newVal) this.hideSelection(false);
  }

  handleResize() {
    this.screenWidth = screen.width;
  }

  IsMobile() {
    return this.screenWidth <= 950;
  }

  selectSeason(seasonIndex: number) {
    this.selectedSeason = seasonIndex;
  }

  selectEpisode(episode: any) {
    if (this.$props.progressId != -1) {
      if (new Date(episode.airstamp) > new Date()) {
        this.DisplaySnackbar("Can't set progress to unaired episode. Sorry.");
      } else {
        axios
          .post(this.apiUri + "/api/list/progress", {
            accountId: LoginService.GetAccountId(this.$cookies),
            sessionCode: LoginService.GetSessionCode(this.$cookies),
            progressId: this.$props.progressId,
            episodeId: episode.id,
          })
          .then(this.updateProgressRespone.bind(this))
          .catch(this.axiosError.bind(this));
      }
    }
  }

  @Emit("displaySnackbar")
  DisplaySnackbar(message: string) {
    return message;
  }

  updateProgressRespone(response: AxiosResponse<any>) {
    this.hideSelection(true);
  }
  axiosError(error: AxiosError<any>) {}

  getEpisodes(seasonId: number) {
    for (var s of this.$props.seasons) {
      if (s.id == seasonId) {
        return s.episodes;
      }
    }
  }

  @Emit("stopUpdatingProgress")
  hideSelection(reload: boolean) {
    return reload;
  }
}
