
import { LoginService } from "@/services/loginService";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Component, Emit, Vue } from "vue-property-decorator";

const StatusSelectorProps = Vue.extend({
  props: {
    visible: Boolean,
    collaboratorId: Number,
    progressId: Number,
  },
});

@Component({
  components: {},
})
export default class StatusSelector extends StatusSelectorProps {
  appConfig = require("../../config.json");
  apiUri = this.appConfig.apiServerUri
    ? this.appConfig.apiServerUri
    : "" + window.location.origin;

  selectedSeason: number = -1;

  scrollbarOptions: any = {
    suppressScrollX: true,
    useBothWheelAxes: true,
  };

  statusTags: any = ["Planned", "OnGoing", "Paused", "Completed"];

  updateStatus(status: number) {
    if (this.$props.progressId != -1) {
      axios
        .post(this.apiUri + "/api/list/status", {
          accountId: LoginService.GetAccountId(this.$cookies),
          sessionCode: LoginService.GetSessionCode(this.$cookies),
          progressId: this.$props.progressId,
          newStatus: status.toString(),
        })
        .then(this.updateStatusRespone.bind(this))
        .catch(this.axiosError.bind(this));
    }
  }

  updateStatusRespone(response: AxiosResponse<any>) {
    this.hideSelection();
  }
  axiosError(error: AxiosError<any>) {}

  @Emit("stopUpdatingStatus")
  hideSelection() {
    return false;
  }
}
