import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(PerfectScrollbar);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
